import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

// import swal from 'sweetalert';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const Cate = (props) =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    
      const [MainCatogories , setMainCatogories] = useState([])



      useEffect(() =>{


        fetch("https://kartbudget-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })

},[])


// const changeFruit = (goto) => {
//   // setCurrentFruit(newFruit)
//   props.history.push(goto)
// }



// function redirect(goto){
//   if (goto != '') {
//       props.history.push(goto)
//       window.location = goto;
//   }
// }
// document.getElementById('cat1').onchange = function(){
//   var goto = this.value;
//   redirect(goto);
  
// };
  
    return (

        <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={true}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={2}
  autoPlay = {true}
  transitionDuration={2000}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 
{MainCatogories.map((res,i)=>{
                        return (
                          <div className="category position-relative">
                      <div className="category-image">
                        <Link to={"/categories/"+res.MainCategories+"/all-products"}  >
                          <img src={res.photo} className="w-100" alt="" width={166} height={160} style={{borderRadius:"50%"}} />
                        </Link>
                      </div>
                      <div className="category-body letter-spacing-normal font-size-normal text-center position-absolute text-uppercase">
                        <a href="#" className="category-title text-truncate font-weight-normal"><b style={{background : "white" , padding : "4px 4px",borderRadius : "5px"}}>{res.MainCategories}</b> </a>
                      </div>
                    </div>
                          )
                        })
            
                        }
          
</Carousel>

    )
}

export default Cate