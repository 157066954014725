import React,{useState , useEffect} from 'react'
import { Link } from 'react-router-dom'


// import blog_1 from '../assets/images/blog_1.jpg';
// import par1 from '../assets/images/par1.jpg';
// import par2 from '../assets/images/par2.jpg';
// import par3 from '../assets/images/par3.jpg';
// import par4 from '../assets/images/par4.jpg';
// import par5 from '../assets/images/par5.jpg';
// import par6 from '../assets/images/par6.jpg';
// import back from '../assets/images/back.jpg';


// import b1 from '../assets/images/b1.jpg';
// import b2 from '../assets/images/b2.jpg';
// import b3 from '../assets/images/b3.jpg';
// import b4 from '../assets/images/b4.jpg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


const SubCategories = (props) => {

    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [product1 , setproduct1] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [CategoriesName , setCategoriesname] = useState("")
    const [SubCategoriesName , setSubCategoriesname] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [Heading , setHeading] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)

        const dar = setInterval(() => {
            let Categoriesname = props.history.location.pathname.split("/")[2] ;
            setCategoriesname(props.history.location.pathname.split("/")[2])
            // console.log(123,props.history.location.pathname.split("/")[2],Categoriesname,props)
            let con = props.history.location.pathname.split("/")[2]
            // console.log(con)
        // console.log(props.history.location.pathname.split("/")[2]);
        
        fetch("https://salman-friend-backend.vercel.app/slider-photo",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
    })
    .then(res=>res.json())
    .then(res1=>{
        // console.log(res1[0]);
        setSliderPhoto(res1)
    
    })
        fetch("https://salman-friend-backend.vercel.app/createMainCateLine",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
    })
    .then(res5=>res5.json())
    .then(res6=>{
        // console.log(res1[0]);
        const data = res6.filter((res56,i)=>{
            // console.log(res56.Product_Catagories , props.history.location.pathname.split("/")[2]);
            return res56.Product_Catagories === props.history.location.pathname.split("/")[2]
        })
        setHeading(data)
    
    })

    
    fetch("https://salman-friend-backend.vercel.app/AllCategories",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     } ,
    })
    .then(res2=>res2.json())
    .then(res3=>{
        // console.log(res3);
        const Data1 = res3.filter((res4,i)=>{
            if (res4.MainCategories === props.history.location.pathname.split("/")[2])
            return res4
        })
        console.log(Data1,res3)
        setSubCategories(Data1)
        // setSubCategoriesname(res3)
    
    }) 
        },1000);
        
       
    return () => {
        clearInterval(dar)
    }
    }, [])




    return (
       
      <div className="page-wrapper">
      <header className="header">
        <div className="header-top">
          <div className="container">
            <div className="header-left">
              <div className="header-dropdown">
                <a href="">Usd</a>
                <div className="header-menu">
                  <ul>
                    <li><a href="">Eur</a></li>
                    <li><a href="">Usd</a></li>
                  </ul>
                </div>{/* End .header-menu */}
              </div>{/* End .header-dropdown */}
              <div className="header-dropdown">
                <a href="">Eng</a>
                <div className="header-menu">
                  <ul>
                    <li><a href="">English</a></li>
                    <li><a href="">French</a></li>
                    <li><a href="">Spanish</a></li>
                  </ul>
                </div>{/* End .header-menu */}
              </div>{/* End .header-dropdown */}
            </div>{/* End .header-left */}
            <div className="header-right">
              <ul className="top-menu">
                <li>
                  <a href="">Links</a>
                  <ul>
                    <li><a href="tel:#"><i className="icon-phone" />Call: +0123 456 789</a></li>
                    <li><a href="wishlist.html"><i className="icon-heart-o" />Wishlist <span>(3)</span></a></li>
                    <li><a href="about.html">About Us</a></li>
                    <li><a href="contact.html">Contact Us</a></li>
                    <li><a href="#signin-modal" data-toggle="modal"><i className="icon-user" />Login</a></li>
                  </ul>
                </li>
              </ul>{/* End .top-menu */}
            </div>{/* End .header-right */}
          </div>{/* End .container */}
        </div>{/* End .header-top */}
        <div className="header-middle sticky-header">
          <div className="container">
            <div className="header-left">
              <button className="mobile-menu-toggler">
                <span className="sr-only">Toggle mobile menu</span>
                <i className="icon-bars" />
              </button>
              <a href="index-32.html" className="logo">
                <img src="assets/images/logo.png" alt="Molla Logo" width={105} height={25} />
              </a>
              <nav className="main-nav">
                <ul className="menu sf-arrows">
                  <li className="megamenu-container active">
                    <a href="index-32.html" className="sf-with-ul">Home</a>
                    <div className="megamenu demo">
                      <div className="menu-col">
                        <div className="menu-title">Choose your demo</div>{/* End .menu-title */}
                        <div className="demo-list">
                          <div className="demo-item">
                            <a href="index-1.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/1.jpg)'}} />
                              <span className="demo-title">01 - furniture store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-2.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/2.jpg)'}} />
                              <span className="demo-title">02 - furniture store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-3.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/3.jpg)'}} />
                              <span className="demo-title">03 - electronic store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-4.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/4.jpg)'}} />
                              <span className="demo-title">04 - electronic store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-5.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/5.jpg)'}} />
                              <span className="demo-title">05 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-6.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/6.jpg)'}} />
                              <span className="demo-title">06 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-7.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/7.jpg)'}} />
                              <span className="demo-title">07 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-8.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/8.jpg)'}} />
                              <span className="demo-title">08 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-9.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/9.jpg)'}} />
                              <span className="demo-title">09 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item">
                            <a href="index-10.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/10.jpg)'}} />
                              <span className="demo-title">10 - shoes store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-11.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/11.jpg)'}} />
                              <span className="demo-title">11 - furniture simple store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-12.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/12.jpg)'}} />
                              <span className="demo-title">12 - fashion simple store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-13.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/13.jpg)'}} />
                              <span className="demo-title">13 - market</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-14.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/14.jpg)'}} />
                              <span className="demo-title">14 - market fullwidth</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-15.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/15.jpg)'}} />
                              <span className="demo-title">15 - lookbook 1</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-16.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/16.jpg)'}} />
                              <span className="demo-title">16 - lookbook 2</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-17.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/17.jpg)'}} />
                              <span className="demo-title">17 - fashion store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-18.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/18.jpg)'}} />
                              <span className="demo-title">18 - fashion store (with sidebar)</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-19.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/19.jpg)'}} />
                              <span className="demo-title">19 - games store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-20.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/20.jpg)'}} />
                              <span className="demo-title">20 - book store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-21.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/21.jpg)'}} />
                              <span className="demo-title">21 - sport store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-22.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/22.jpg)'}} />
                              <span className="demo-title">22 - tools store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-23.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/23.jpg)'}} />
                              <span className="demo-title">23 - fashion left navigation store</span>
                            </a>
                          </div>{/* End .demo-item */}
                          <div className="demo-item hidden">
                            <a href="index-24.html">
                              <span className="demo-bg" style={{backgroundImage: 'url(assets/images/menu/demos/24.jpg)'}} />
                              <span className="demo-title">24 - extreme sport store</span>
                            </a>
                          </div>{/* End .demo-item */}
                        </div>{/* End .demo-list */}
                        <div className="megamenu-action text-center">
                          <a href="" className="btn btn-outline-primary-2 view-all-demos"><span>View All Demos</span><i className="icon-long-arrow-right" /></a>
                        </div>{/* End .text-center */}
                      </div>{/* End .menu-col */}
                    </div>{/* End .megamenu */}
                  </li>
                  <li>
                    <a href="category.html" className="sf-with-ul">Shop</a>
                    <div className="megamenu megamenu-md">
                      <div className="row no-gutters">
                        <div className="col-md-8">
                          <div className="menu-col">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="menu-title">Shop with sidebar</div>{/* End .menu-title */}
                                <ul>
                                  <li><a href="category-list.html">Shop List</a></li>
                                  <li><a href="category-2cols.html">Shop Grid 2 Columns</a></li>
                                  <li><a href="category.html">Shop Grid 3 Columns</a></li>
                                  <li><a href="category-4cols.html">Shop Grid 4 Columns</a></li>
                                  <li><a href="category-market.html"><span>Shop Market<span className="tip tip-new">New</span></span></a></li>
                                </ul>
                                <div className="menu-title">Shop no sidebar</div>{/* End .menu-title */}
                                <ul>
                                  <li><a href="category-boxed.html"><span>Shop Boxed No Sidebar<span className="tip tip-hot">Hot</span></span></a></li>
                                  <li><a href="category-fullwidth.html">Shop Fullwidth No Sidebar</a></li>
                                </ul>
                              </div>{/* End .col-md-6 */}
                              <div className="col-md-6">
                                <div className="menu-title">Product Category</div>{/* End .menu-title */}
                                <ul>
                                  <li><a href="product-category-boxed.html">Product Category Boxed</a></li>
                                  <li><a href="product-category-fullwidth.html"><span>Product Category Fullwidth<span className="tip tip-new">New</span></span></a></li>
                                </ul>
                                <div className="menu-title">Shop Pages</div>{/* End .menu-title */}
                                <ul>
                                  <li><a href="cart.html">Cart</a></li>
                                  <li><a href="checkout.html">Checkout</a></li>
                                  <li><a href="wishlist.html">Wishlist</a></li>
                                  <li><a href="dashboard.html">My Account</a></li>
                                  <li><a href="">Lookbook</a></li>
                                </ul>
                              </div>{/* End .col-md-6 */}
                            </div>{/* End .row */}
                          </div>{/* End .menu-col */}
                        </div>{/* End .col-md-8 */}
                        <div className="col-md-4">
                          <div className="banner banner-overlay">
                            <a href="category.html" className="banner banner-menu">
                              <img src="assets/images/menu/banner-1.jpg" alt="Banner" />
                              <div className="banner-content banner-content-top">
                                <div className="banner-title text-white">Last <br />Chance<br /><span><strong>Sale</strong></span></div>{/* End .banner-title */}
                              </div>{/* End .banner-content */}
                            </a>
                          </div>{/* End .banner banner-overlay */}
                        </div>{/* End .col-md-4 */}
                      </div>{/* End .row */}
                    </div>{/* End .megamenu megamenu-md */}
                  </li>
                  <li>
                    <a href="product.html" className="sf-with-ul">Product</a>
                    <div className="megamenu megamenu-sm">
                      <div className="row no-gutters">
                        <div className="col-md-6">
                          <div className="menu-col">
                            <div className="menu-title">Product Details</div>{/* End .menu-title */}
                            <ul>
                              <li><a href="product.html">Default</a></li>
                              <li><a href="product-centered.html">Centered</a></li>
                              <li><a href="product-extended.html"><span>Extended Info<span className="tip tip-new">New</span></span></a></li>
                              <li><a href="product-gallery.html">Gallery</a></li>
                              <li><a href="product-sticky.html">Sticky Info</a></li>
                              <li><a href="product-sidebar.html">Boxed With Sidebar</a></li>
                              <li><a href="product-fullwidth.html">Full Width</a></li>
                              <li><a href="product-masonry.html">Masonry Sticky Info</a></li>
                            </ul>
                          </div>{/* End .menu-col */}
                        </div>{/* End .col-md-6 */}
                        <div className="col-md-6">
                          <div className="banner banner-overlay">
                            <a href="category.html">
                              <img src="assets/images/menu/banner-2.jpg" alt="Banner" />
                              <div className="banner-content banner-content-bottom">
                                <div className="banner-title text-white">New Trends<br /><span><strong>spring 2019</strong></span></div>{/* End .banner-title */}
                              </div>{/* End .banner-content */}
                            </a>
                          </div>{/* End .banner */}
                        </div>{/* End .col-md-6 */}
                      </div>{/* End .row */}
                    </div>{/* End .megamenu megamenu-sm */}
                  </li>
                  <li>
                    <a href="" className="sf-with-ul">Pages</a>
                    <ul>
                      <li>
                        <a href="about.html" className="sf-with-ul">About</a>
                        <ul>
                          <li><a href="about.html">About 01</a></li>
                          <li><a href="about-2.html">About 02</a></li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html" className="sf-with-ul">Contact</a>
                        <ul>
                          <li><a href="contact.html">Contact 01</a></li>
                          <li><a href="contact-2.html">Contact 02</a></li>
                        </ul>
                      </li>
                      <li><a href="login.html">Login</a></li>
                      <li><a href="faq.html">FAQs</a></li>
                      <li><a href="404.html">Error 404</a></li>
                      <li><a href="coming-soon.html">Coming Soon</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="blog.html" className="sf-with-ul">Blog</a>
                    <ul>
                      <li><a href="blog.html">Classic</a></li>
                      <li><a href="blog-listing.html">Listing</a></li>
                      <li>
                        <a href="">Grid</a>
                        <ul>
                          <li><a href="blog-grid-2cols.html">Grid 2 columns</a></li>
                          <li><a href="blog-grid-3cols.html">Grid 3 columns</a></li>
                          <li><a href="blog-grid-4cols.html">Grid 4 columns</a></li>
                          <li><a href="blog-grid-sidebar.html">Grid sidebar</a></li>
                        </ul>
                      </li>
                      <li>
                        <a href="">Masonry</a>
                        <ul>
                          <li><a href="blog-masonry-2cols.html">Masonry 2 columns</a></li>
                          <li><a href="blog-masonry-3cols.html">Masonry 3 columns</a></li>
                          <li><a href="blog-masonry-4cols.html">Masonry 4 columns</a></li>
                          <li><a href="blog-masonry-sidebar.html">Masonry sidebar</a></li>
                        </ul>
                      </li>
                      <li>
                        <a href="">Mask</a>
                        <ul>
                          <li><a href="blog-mask-grid.html">Blog mask grid</a></li>
                          <li><a href="blog-mask-masonry.html">Blog mask masonry</a></li>
                        </ul>
                      </li>
                      <li>
                        <a href="">Single Post</a>
                        <ul>
                          <li><a href="single.html">Default with sidebar</a></li>
                          <li><a href="single-fullwidth.html">Fullwidth no sidebar</a></li>
                          <li><a href="single-fullwidth-sidebar.html">Fullwidth with sidebar</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="elements-list.html" className="sf-with-ul">Elements</a>
                    <ul>
                      <li><a href="elements-products.html">Products</a></li>
                      <li><a href="elements-typography.html">Typography</a></li>
                      <li><a href="elements-titles.html">Titles</a></li>
                      <li><a href="elements-banners.html">Banners</a></li>
                      <li><a href="elements-product-category.html">Product Category</a></li>
                      <li><a href="elements-video-banners.html">Video Banners</a></li>
                      <li><a href="elements-buttons.html">Buttons</a></li>
                      <li><a href="elements-accordions.html">Accordions</a></li>
                      <li><a href="elements-tabs.html">Tabs</a></li>
                      <li><a href="elements-testimonials.html">Testimonials</a></li>
                      <li><a href="elements-blog-posts.html">Blog Posts</a></li>
                      <li><a href="elements-portfolio.html">Portfolio</a></li>
                      <li><a href="elements-cta.html">Call to Action</a></li>
                      <li><a href="elements-icon-boxes.html">Icon Boxes</a></li>
                    </ul>
                  </li>
                </ul>{/* End .menu */}
              </nav>{/* End .main-nav */}
            </div>{/* End .header-left */}
            <div className="header-right">
              <div className="header-search">
                <a href="" className="search-toggle" role="button" title="Search"><i className="icon-search" /></a>
                <form action="#" method="get">
                  <div className="header-search-wrapper">
                    <label htmlFor="q" className="sr-only">Search</label>
                    <input type="search" className="form-control" name="q" id="q" placeholder="Search in..." required />
                  </div>{/* End .header-search-wrapper */}
                </form>
              </div>{/* End .header-search */}
              <div className="dropdown compare-dropdown">
                <a href="" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Compare Products" aria-label="Compare Products">
                  <i className="icon-random" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="compare-products">
                    <li className="compare-product">
                      <a href="" className="btn-remove" title="Remove Product"><i className="icon-close" /></a>
                      <h4 className="compare-product-title"><a href="product.html">Blue Night Dress</a></h4>
                    </li>
                    <li className="compare-product">
                      <a href="" className="btn-remove" title="Remove Product"><i className="icon-close" /></a>
                      <h4 className="compare-product-title"><a href="product.html">White Long Skirt</a></h4>
                    </li>
                  </ul>
                  <div className="compare-actions">
                    <a href="" className="action-link">Clear All</a>
                    <a href="" className="btn btn-outline-primary-2"><span>Compare</span><i className="icon-long-arrow-right" /></a>
                  </div>
                </div>{/* End .dropdown-menu */}
              </div>{/* End .compare-dropdown */}
              <div className="dropdown cart-dropdown">
                <a href="" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                  <i className="icon-shopping-cart" />
                  <span className="cart-count">2</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <div className="dropdown-cart-products">
                    <div className="product">
                      <div className="product-cart-details">
                        <h4 className="product-title">
                          <a href="product.html">Beige knitted elastic runner shoes</a>
                        </h4>
                        <span className="cart-product-info">
                          <span className="cart-product-qty">1</span>
                          x $84.00
                        </span>
                      </div>{/* End .product-cart-details */}
                      <figure className="product-image-container">
                        <a href="product.html" className="product-image">
                          <img src="assets/images/products/cart/product-1.jpg" alt="product" />
                        </a>
                      </figure>
                      <a href="" className="btn-remove" title="Remove Product"><i className="icon-close" /></a>
                    </div>{/* End .product */}
                    <div className="product">
                      <div className="product-cart-details">
                        <h4 className="product-title">
                          <a href="product.html">Blue utility pinafore denim dress</a>
                        </h4>
                        <span className="cart-product-info">
                          <span className="cart-product-qty">1</span>
                          x $76.00
                        </span>
                      </div>{/* End .product-cart-details */}
                      <figure className="product-image-container">
                        <a href="product.html" className="product-image">
                          <img src="assets/images/products/cart/product-2.jpg" alt="product" />
                        </a>
                      </figure>
                      <a href="" className="btn-remove" title="Remove Product"><i className="icon-close" /></a>
                    </div>{/* End .product */}
                  </div>{/* End .cart-product */}
                  <div className="dropdown-cart-total">
                    <span>Total</span>
                    <span className="cart-total-price">$160.00</span>
                  </div>{/* End .dropdown-cart-total */}
                  <div className="dropdown-cart-action">
                    <a href="cart.html" className="btn btn-primary">View Cart</a>
                    <a href="checkout.html" className="btn btn-outline-primary-2"><span>Checkout</span><i className="icon-long-arrow-right" /></a>
                  </div>{/* End .dropdown-cart-total */}
                </div>{/* End .dropdown-menu */}
              </div>{/* End .cart-dropdown */}
            </div>{/* End .header-right */}
          </div>{/* End .container */}
        </div>{/* End .header-middle */}
      </header>{/* End .header */}
      <main className="main">
        <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
          <div className="container">
            <h1 className="page-title">Product Category Boxed<span>Shop</span></h1>
          </div>{/* End .container */}
        </div>{/* End .page-header */}
        <nav aria-label="breadcrumb" className="breadcrumb-nav breadcrumb-with-filter">
          <div className="container">
            <a href="" className="sidebar-toggler"><i className="icon-bars" />Filters</a>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
              <li className="breadcrumb-item"><a href="">Shop</a></li>
              <li className="breadcrumb-item"><a href="">Product Category</a></li>
              <li className="breadcrumb-item active" aria-current="page">Boxed</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="page-content">
          <div className="categories-page">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-1.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Dresses</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">3 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-2.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Jackets</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">2 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                </div>{/* End .col-md-6 */}
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="banner banner-cat banner-badge">
                        <a href="">
                          <img src="assets/images/category/boxed/banner-3.jpg" alt="Banner" />
                        </a>
                        <a className="banner-link" href="">
                          <h3 className="banner-title">T-shirts</h3>{/* End .banner-title */}
                          <h4 className="banner-subtitle">0 Products</h4>{/* End .banner-subtitle */}
                          <span className="banner-link-text">Shop Now</span>
                        </a>{/* End .banner-link */}
                      </div>{/* End .banner */}
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <div className="banner banner-cat banner-badge">
                        <a href="">
                          <img src="assets/images/category/boxed/banner-4.jpg" alt="Banner" />
                        </a>
                        <a className="banner-link" href="">
                          <h3 className="banner-title">Jeans</h3>{/* End .banner-title */}
                          <h4 className="banner-subtitle">1 Products</h4>{/* End .banner-subtitle */}
                          <span className="banner-link-text">Shop Now</span>
                        </a>{/* End .banner-link */}
                      </div>{/* End .banner */}
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-5.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Bags</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">4 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                </div>{/* End .col-md-6 */}
                <div className="col-sm-6 col-md-3">
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-6.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Sportwear</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">0 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                </div>{/* End .col-md-3 */}
                <div className="col-sm-6 col-md-3 order-md-last">
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-8.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Jumpers</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">1 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                </div>{/* End .col-md-3 */}
                <div className="col-md-6">
                  <div className="banner banner-cat banner-badge">
                    <a href="">
                      <img src="assets/images/category/boxed/banner-7.jpg" alt="Banner" />
                    </a>
                    <a className="banner-link" href="">
                      <h3 className="banner-title">Shoes</h3>{/* End .banner-title */}
                      <h4 className="banner-subtitle">2 Products</h4>{/* End .banner-subtitle */}
                      <span className="banner-link-text">Shop Now</span>
                    </a>{/* End .banner-link */}
                  </div>{/* End .banner */}
                </div>{/* End .col-md-6 */}
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .categories-page */}
          <div className="sidebar-filter-overlay" />{/* End .sidebar-filter-overlay */}
          <aside className="sidebar-shop sidebar-filter sidebar-filter-banner">
            <div className="sidebar-filter-wrapper">
              <div className="widget widget-clean">
                <label><i className="icon-close" />Filters</label>
                <a href="" className="sidebar-filter-clear">Clean All</a>
              </div>
              <div className="widget">
                <h3 className="widget-title">
                  Browse Category
                </h3>{/* End .widget-title */}
                <div className="widget-body">
                  <div className="filter-items filter-items-count">
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-1" />
                        <label className="custom-control-label" htmlFor="cat-1">Women</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">3</span>
                    </div>{/* End .filter-item */}
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-2" />
                        <label className="custom-control-label" htmlFor="cat-2">Men</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">0</span>
                    </div>{/* End .filter-item */}
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-3" />
                        <label className="custom-control-label" htmlFor="cat-3">Holiday Shop</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">0</span>
                    </div>{/* End .filter-item */}
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-4" />
                        <label className="custom-control-label" htmlFor="cat-4">Gifts</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">0</span>
                    </div>{/* End .filter-item */}
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-5" />
                        <label className="custom-control-label" htmlFor="cat-5">Homeware</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">0</span>
                    </div>{/* End .filter-item */}
                    <div className="filter-item">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="cat-6" defaultChecked="checked" />
                        <label className="custom-control-label" htmlFor="cat-6">Grid Categories Fullwidth</label>
                      </div>{/* End .custom-checkbox */}
                      <span className="item-count">13</span>
                    </div>{/* End .filter-item */}
                    <div className="sub-filter-items">
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-7" />
                          <label className="custom-control-label" htmlFor="cat-7">Dresses</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">3</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-8" />
                          <label className="custom-control-label" htmlFor="cat-8">T-shirts</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">0</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-9" />
                          <label className="custom-control-label" htmlFor="cat-9">Bags</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">4</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-10" />
                          <label className="custom-control-label" htmlFor="cat-10">Jackets</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">2</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-11" />
                          <label className="custom-control-label" htmlFor="cat-11">Shoes</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">2</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-12" />
                          <label className="custom-control-label" htmlFor="cat-12">Jumpers</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">1</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-13" />
                          <label className="custom-control-label" htmlFor="cat-13">Jeans</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">1</span>
                      </div>{/* End .filter-item */}
                      <div className="filter-item">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="cat-14" />
                          <label className="custom-control-label" htmlFor="cat-14">Sportwear</label>
                        </div>{/* End .custom-checkbox */}
                        <span className="item-count">0</span>
                      </div>{/* End .filter-item */}
                    </div>{/* End .sub-filter-items */}
                  </div>{/* End .filter-items */}
                </div>{/* End .widget-body */}
              </div>{/* End .widget */}
            </div>{/* End .sidebar-filter-wrapper */}
          </aside>{/* End .sidebar-filter */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}
    </div>

    )
}

export default SubCategories ;