import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
                 <h4>KartBudget: Affordable Online Shopping Store</h4>

<p>Shopping online with KartBudget comes with many perks. Take your time to explore a wide variety of options at your convenience. Compare prices effortlessly and find exactly what you're searching for, all at the most competitive rates. Whether you're shopping for yourself or seeking <b>cool gifts</b> for your loved ones, KartBudget's got you covered.</p>
<br />
<h4 style={{marginBottom : "1px"}}>An Extensive Product Range</h4>
<p>We offer an extensive inventory spanning from clothing, cosmetics, and home essentials to kitchen products and more. With over 50 lakh products and 650+ categories, KartBudget is your one-stop shop. Our latest collections feature popular items at budget-friendly prices, ensuring you get the best deals on new attire, accessories, and everyday home items.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Fashion for Women</h4>
<p>Discover our Women's Ethnic Wear collection, where you'll find everything from traditional sarees to modern suits and Kurtis. Accessorize with our stunning jewelry, footwear, and accessories for a complete head-turning look. KartBudget is also your go-to destination for affordable Western wear, offering the latest fashion trends along with funky jewelry and accessories.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Fashion for Men</h4>
<p>Explore our Men's Ethnic Wear collection for budget-friendly Sherwanis, Kurta sets, and more. In our extensive range of Men's Western Wear, you'll find t-shirts, jeans, polos, coats, and shirts to suit your style. We have something for everyone, available in various sizes and at affordable prices. Don't forget to check out our accessories and footwear selection.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Top-Quality Beauty Products</h4>
<p>KartBudget prioritizes quality in cosmetics and skincare. Find the <b>best makeup and skincare</b> products, from foundations to lipsticks, that won't harm your skin. Our range includes makeup removers, concealers, primer, mascara, and eye shadow, ensuring you look your best.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Trendy Kids' Collection</h4>
<p>Kids grow quickly, and our kidswear collection is here to keep them stylish without breaking the bank. Shop for girls' rompers and dresses or boys' sets and jeans. We also offer ethnic wear and accessories for kids of all ages, plus a baby care collection with essential products.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Home & Kitchen, Electronics, and More</h4>
<p>For your kitchen, home, and electronic needs, KartBudget provides a wide array of products. From cooking utensils to serveware and kitchen appliances, we offer quality items at reasonable prices. Refresh your home's style with our home furnishing items, including bedsheets, curtains, cushions, and trendy decor items. You can also find electronics and accessories, including chargers, headphones, data cables, and more, with regular sales and discounts.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Health & Fitness</h4>
<p>We understand the importance of health and fitness. KartBudget offers an affordable range of fitness equipment, including dumbbell sets, exercise bands, mats, skipping ropes, and sports kits, to kickstart your fitness journey.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Office Supplies and Stationery</h4>
<p>Stay organized with our range of stationery items. Whether you need supplies for school, college, or the office, we have you covered with notebooks, diaries, notepads, pens, pencils, and more.</p>

<p>Enjoy a simplified shopping experience with KartBudget - your affordable online shopping destination.</p>

            </div>
            </div>
            </div>
            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;