import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import swal from 'sweetalert';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const iconBox = () =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    
  
    return (

        <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={false}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={1}
  autoPlay = {true}
  transitionDuration={3000}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={4000}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 


<div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="icon-box-icon mb-0">
                        <i className="icon-truck py-2 pt-0 second-primary-color" />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Payment &amp; Delivery</h3>
                        <p className="font-weight-light second-primary-color">Free shipping for orders over $50</p>
                      </div>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="icon-box-icon text-dark mb-0">
                        <i className="icon-rotate-left py-2 pt-0 second-primary-color" />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Return &amp; Refund</h3>
                        <p className="font-weight-light second-primary-color">Free 100% money back guarantee</p>
                      </div>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="icon-box-icon text-dark mb-0">
                        <i className="icon-life-ring py-2 pt-0 second-primary-color" />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Quality Support</h3>
                        <p className="font-weight-light second-primary-color">Alway online feedback 24/7</p>
                      </div>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="icon-box-icon text-dark mb-0">
                        <i className="icon-envelope py-2 pt-0 second-primary-color" />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">JOIN OUR NEWSLETTER</h3>
                        <p className="font-weight-light second-primary-color">10% off by subscribing to our newsletter</p>
                      </div>
                    </div>
          
</Carousel>

    )
}

export default iconBox