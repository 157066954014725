import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import slid1 from '../assets/images/slid1.webp';
// import slid2 from '../assets/images/slid2.webp';
import slid14 from '../assets/images/slid14.jpg';
import slid15 from '../assets/images/slid15.jpg';
import slid16 from '../assets/images/slid16.jpg';
import slid17 from '../assets/images/slid17.jpg';
import slid0 from '../assets/images/slid0.jpg';


import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';
import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
import Carousel2 from './Carosel1';
import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
import Carousel5 from './Carosel4';
import Carousel6 from './Carosel5';
import Carousel7 from './Carosel6';
import Ico from './iconBox';
import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";





const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const  responsive= {
      0: {
          items: 1,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      1000: {
          items: 4,
      },
  }
    const  responsive1= {
      0: {
          items: 2,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      750: {
          items: 4,
      },
      1000: {
          items: 7,
      },
  }
    const  responsive2= {
      0: {
          items: 2,
      },
     
      450: {
          items: 3,
      },
      600: {
          items: 4,
      },
      1000: {
          items: 6,
      },
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    const [chk1 , setchk1] = useState(true)
    const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])

    const [Ite1 , setIte1] = useState(1)
    const [Ite2 , setIte2] = useState(1)

    const [fg , setfg] = useState(true)
    // var settings = {
    //   infinite: true,
    //   dots: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   lazyLoad: true,
    //   autoplay: true,
    // autoplaySpeed: 2000,
    // };
    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://kartbudget-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto(res1)
          })
          // carrouselInit()
        fetch("https://kartbudget-main-back.vercel.app/companieswith",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res9=>res9.json())
      .then(res11=>{
          console.log(res11[0]);
          setcompanieswith(res11)
      
      })
        fetch("https://kartbudget-main-back.vercel.app/BannerPhoto",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res22=>res22.json())
        .then(res222=>{
            console.log(res222[0]);
            setBannerPhoto(res222)
        
        })

        fetch("https://kartbudget-main-back.vercel.app/arrival-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            setArrivalPhoto(res3)
        
        })
       

        fetch("https://kartbudget-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://kartbudget-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://kartbudget-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        fetch("https://kartbudget-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://kartbudget-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://kartbudget-main-back.vercel.app/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
        fetch("https://kartbudget-main-back.vercel.app/FAQ1",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res3=>res3.json())
      .then(res4=>{
        let dat1 = []
        res4.map((res55,c)=>{
            dat1.push(0)
        })
        setfvalue(dat1)
             setdata(res4)
             data.map((res,i)=>{
              data1[i] = res.text
             })

          console.log(res4)
        })
        fetch("https://kartbudget-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))
    // setproduct1(pro1.slice(0,7))
    // setproduct1(pro1)
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/Rs. {Cat1}`)
        },1500)
    }
    
    
//     const addtocartproduct = (data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


//        if(data111.ProductCreater !== "60a3c644e4520a12c80a6f52"){
//         console.log("yes ");
//         fetch("https://kartbudget-main-back.vercel.app/ChangePercentageInOrder/"+data111.ProductCreater,{
//                             method: "POST",
//                             headers :  {
//                                 "Content-Type" : "application/json" , 
//                             }
//                         })
//                         .then(res=>res.json())
//                         .then((res1)=>{ 
//                             console.log(res1);
//                             // if(!res1 === null){
// document.getElementById("myDi").style.visibility = "visible"
//     setTimeout(()=>{
//         document.getElementById("myDi").style.visibility = "hidden"

//     },1000)
//     if (data111.Product_Price_Discounted === null){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Product_Price_Discounted){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price_Discounted *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Size_Discounted[0]){
//         const data =  {...data111 ,
//             Percentage : data111.Percentage,
//             DeliveryStatus : "Pending",
//             Pieces :1,
//             Size : data111.Size[0],
//             Product_Price_Discounted : data111.Size_Discounted[0],
//             Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
            
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data]
            
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }
//     }


//     else{




//                         const data =  {...data111 ,
//                             Percentage : data111.Percentage,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
              
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]
             
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//             }


//     }


//   })
    

// }













// else{
  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 // console.log(productDetail);
//                 if (data111.Product_Price_Discounted === null){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Product_Price_Discounted){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price_Discounted *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Size_Discounted[0]){
//                     const data =  {...data111 ,
//                         Percentage : 0,
//                         DeliveryStatus : "Pending",
//                         Pieces :1,
//                         Size : data111.Size[0],
//                         Product_Price_Discounted : data111.Size_Discounted[0],
//                         Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )

//             }
//             else{
//             console.log("Differet");
//             var data2 = [...data1 , data111]

//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }

//                 }
//                 else{




//                         const data =  {...data111 ,
//                             Percentage : 0,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 if (data1){
//                 var data3 = data1.map((item) => {
//                     if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }


//                 }


//                     }






































//     }

    
const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
    const carrouselInit =() => {

      var carrousel = document.getElementById("carrouselimg1");
      carrousel.style.opacity = "1";
      setInterval(carrouselAutoChange, 3500);
    
    }
    
    const carrouselAutoChange=()  =>{
      carrouselRight();
    }
    
    
    
    const carrouselChange=(i) => {
    
      var carrousel = document.getElementById("carrouselimg" + i);
      carrousel.style.opacity = "1";
    
    }
    
    
    const carrouselLeft=()  =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 0) {
                  return carrouselChange(4);
              }
              return carrouselChange(num - 1);
          }
      }
    }
    
    const carrouselRight=() =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 3) {
                  return carrouselChange(1);
              }
              return carrouselChange(num + 1);
          }
      }
    }
    
    
    // const addtocartproduct = (productDetail) =>{
    //   swal("Update!", "Your Item Add in Cart!", "success");
    
    //   // if(productDetail){
    //       // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
    //                   if (productDetail[0].Product_Price_Discounted === null){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    //                   else if (productDetail[0].Product_Price_Discounted){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price_Discounted *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price_Discounted *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    
    
                          
    
    // }
    
    


const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}

const addtocartproduct8 = (productDetail) =>{
  swal("Update!", "Item Save!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }


  }


      

}

    
        return (
            
          <div>
          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{backgroundColor: '#fafafa'}}>
              <div className="intro-section change-height-slider" >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5cols d-none d-lg-block" style={{maxHeight: "500px",
    overflowY: "scroll"}}>
                      <nav className="side-nav">
                        <div className="sidenav-title letter-spacing-normal font-size-normal d-flex justify-content-xl-between align-items-center bg-primary justify-content-center text-truncate">Browse Categories
                          <i className="icon-bars float-right h5 text-white m-0 d-none d-xl-block" />
                        </div>
                        {/* End .sidenav-title   font-size-normal */}
                        <ul className="menu-vertical sf-arrows sf-js-enabled" style={{touchAction: 'pan-y'}}>
                          
                          {/* <li className="megamenu-container">
                            <a className="sf-with-ul text-dark" href="#">
                              <i className="icon-concierge-bell" />Cooking</a>
                            <div className="megamenu">
                              <div className="menu-col">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="menu-title">Cookware</div>
                                    <ul>
                                      <li>
                                        <a href="#">Cookware Sets</a>
                                      </li>
                                      <li>
                                        <a href="#">Pans, Griddles &amp;Woks</a>
                                      </li>
                                      <li>
                                        <a href="#">Pots</a>
                                      </li>
                                      <li>
                                        <a href="#">Skillets &amp;Grill Pans</a>
                                      </li>
                                      <li>
                                        <a href="#">Kettles</a>
                                      </li>
                                      <li>
                                        <a href="#">Soup &amp;Stockpots</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="menu-title">Dinnerware &amp;Tabletop</div>
                                    <ul>
                                      <li>
                                        <a href="#">Plates</a>
                                      </li>
                                      <li>
                                        <a href="#">Cups &amp;Mugs</a>
                                      </li>
                                      <li>
                                        <a href="#">Trays &amp;Platters</a>
                                      </li>
                                      <li>
                                        <a href="#">Coffee &amp;Tea Serving</a>
                                      </li>
                                      <li>
                                        <a href="#">Salt &amp;Pepper Shaker</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="menu-title">Cooking Appliances</div>
                                    <ul>
                                      <li>
                                        <a href="#">Microwaves</a>
                                      </li>
                                      <li>
                                        <a href="#">Coffee Makers</a>
                                      </li>
                                      <li>
                                        <a href="#">Mixers &amp;Attachments</a>
                                      </li>
                                      <li>
                                        <a href="#">Slow Cookers</a>
                                      </li>
                                      <li>
                                        <a href="#">Air Fryers</a>
                                      </li>
                                      <li>
                                        <a href="#">Toasters &amp;Ovens</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="row menu-banners">
                                  <div className="col-md-4">
                                    <div className="banner h-100">
                                      <a href="#">
                                        <img src="assets/images/demos/demo-13/menu/1.jpg" alt="image" width={251} height={141} />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="banner h-100">
                                      <a href="#">
                                        <img src="assets/images/demos/demo-13/menu/2.jpg" alt="image" width={251} height={141} />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-md-4 h-100">
                                    <div className="banner">
                                      <a href="#">
                                        <img src="assets/images/demos/demo-13/menu/3.jpg" alt="image" width={251} height={141} />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> */}
                          

                          {MainCatogories.map((res,i)=>{
                            if(i % 2 === 0){
                    return (
                     
<li className="megamenu-container">
                            <Link className="sf-with-ul text-dark" to={"/categories/"+res.MainCategories +"/all-products"}>
                              <i className="icon-laptop" />{res.MainCategories}</Link>
                            <div className="megamenu">
                              <div className="row ">
                                <div className="col-md-8">
                                  <div className="menu-col">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="menu-title">Laptops &amp; Computers</div>
                                        {/* End .menu-title */}
                                        <ul>
                                          <li>
                                            <a href="#">Desktop Computers</a>
                                          </li>
                                          <li>
                                            <a href="#">Monitors</a>
                                          </li>
                                          <li>
                                            <a href="#">Laptops</a>
                                          </li>
                                          <li>
                                            <a href="#">iPad &amp; Tablets</a>
                                          </li>
                                          <li>
                                            <a href="#">Hard Drives &amp; Storage</a>
                                          </li>
                                          <li>
                                            <a href="#">Printers &amp; Supplies</a>
                                          </li>
                                          <li>
                                            <a href="#">Computer Accessories</a>
                                          </li>
                                        </ul>
                                        <div className="menu-title">TV &amp; Video</div>
                                        {/* End .menu-title */}
                                        <ul>
                                          <li>
                                            <a href="#">TVs</a>
                                          </li>
                                          <li>
                                            <a href="#">Home Audio Speakers</a>
                                          </li>
                                          <li>
                                            <a href="#">Projectors</a>
                                          </li>
                                          <li>
                                            <a href="#">Media Streaming Devices</a>
                                          </li>
                                        </ul>
                                      </div>
                                      {/* End .col-md-6 */}
                                      <div className="col-md-6">
                                        <div className="menu-title">Cell Phones</div>
                                        {/* End .menu-title */}
                                        <ul>
                                          <li>
                                            <a href="#">Carrier Phones</a>
                                          </li>
                                          <li>
                                            <a href="#">Unlocked Phones</a>
                                          </li>
                                          <li>
                                            <a href="#">Phone &amp; Cellphone Cases</a>
                                          </li>
                                          <li>
                                            <a href="#">Cellphone Chargers </a>
                                          </li>
                                        </ul>
                                        <div className="menu-title">Digital Cameras</div>
                                        {/* End .menu-title */}
                                        <ul>
                                          <li>
                                            <a href="#">Digital SLR Cameras</a>
                                          </li>
                                          <li>
                                            <a href="#">Sports &amp; Action Cameras</a>
                                          </li>
                                          <li>
                                            <a href="#">Camcorders</a>
                                          </li>
                                          <li>
                                            <a href="#">Camera Lenses</a>
                                          </li>
                                          <li>
                                            <a href="#">Photo Printer</a>
                                          </li>
                                          <li>
                                            <a href="#">Digital Memory Cards</a>
                                          </li>
                                          <li>
                                            <a href="#">Camera Bags, Backpacks &amp; Cases</a>
                                          </li>
                                        </ul>
                                      </div>
                                      {/* End .col-md-6 */}
                                    </div>
                                    {/* End .row */}
                                  </div>
                                  {/* End .menu-col */}
                                </div>
                                {/* End .col-md-8 */}
                                <div className="col-md-4">
                                  <div className="banner banner-overlay h-100">
                                    <a href="category.html" className="banner banner-menu">
                                      <img src="assets/images/demos/demo-13/menu/banner-1.jpg" alt="Banner" width={280} height={440} />
                                    </a>
                                  </div>
                                  {/* End .banner banner-overlay */}
                                </div>
                                {/* End .col-md-4 */}
                              </div>
                              {/* End .row */}
                            </div>
                            {/* End .megamenu */}
                          </li>

                        

)

}
                  })
                

                              }
                          {MainCatogories.map((res,i)=>{
                            if(i % 2 !== 0){
                         return (
                      <>
                          <li>
                            <Link to={"/categories/"+res.MainCategories +"/all-products"} className="text-dark">
                              <i className="icon-blender" /> {res.MainCategories}</Link>
                          </li>
                       </>
                    
                    )      
                            }
                   
                  
                  })
                

                              }


                       
                        </ul>
                        {/* End .menu-vertical */}
                      </nav>
                      {/* End .dropdown-menu */}
                    </div>
                    <div className="col-xl-9 col-md-12 col-12 mb-md-0 mb-2">
                      <div className="intro-slider-container">
                        {/* <div className="intro-slider owl-carousel owl-theme owl-nav-inside row cols-1" data-toggle="owl" data-owl-options="{
                                          &quot;nav&quot;: false,
                                          &quot;dots&quot;: true,
                                          &quot;autoplay&quot;: false,
                                          &quot;autoplayTimeout&quot;: 10000,
                                          &quot;animateOut&quot;: &quot;fadeOut&quot;
                                      }"> */}










{/* <Slider {...settings}>   */}

<Carousel autoPlay={true} interval={2000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Mobiles%20&%20Tablets/all-products")}>
    <img src={slid14} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Kitchen%20&%20Dining/all-products")}>
    <img src={slid15} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Toys%20&%20Games/all-products")}>
    <img src={slid16} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Laundry%20&%20Cleaning/all-products")}>
    <img src={slid17} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Mother%20&%20Baby/all-products")}>
    <img src={slid0} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div>




    {/* <div className="intro-slide bg-image d-flex align-items-center" style={{ backgroundImage:`Rs. {slid1}`}}>
    <img src={slid2} alt="" />

    <div className="intro-content">
      <h3 className="intro-subtitle font-size-normal text-dark font-weight-normal text-uppercase">Trade-In Offer</h3>
      <h1 className="intro-title text-dark font-weight-bold mb-0">
        Multi-motion
        <br />Food Processor
      </h1>
      <div className="intro-price text-dark font-weight-normal">
        <sup className="font-weight-normal">from
          <span className="text-primary font-weight-normal">Rs. </span>
        </sup>
        <span className="text-primary font-weight-bold">199
          <sup className="font-weight-normal">.99</sup>
        </span>
      </div>
      <a href="category.html" className="btn btn-primary text-uppercase text-dark">
        <span>Shop Now</span>
        <i className="icon-long-arrow-right" />
      </a>
    </div> 

  </div>*/}
  


















  </Carousel>


                          {/* </Slider> */}

                          {/* End .intro-slide */}
                        </div>
                        {/* End .intro-slider owl-carousel owl-simple */}
                        {/* 
                                      <span class="slider-loader"></span>
                                  End .slider-loader */}
                      {/* </div> */}
                    </div>
                    {/* <div className="col-lg-5cols col-md-3 col-12 mb-md-0 mb-2">
                      <div className="banner banner-overlay bg-image h-100 mb-0" style={{backgroundColor: '#f1f1f1', backgroundImage: 'url(assets/images/demos/demo-26/banners/banner-1.jpg)'}}>
                        <div className="banner-content position-relative pt-0 pb-md-7 d-flex flex-column">
                          <div className="title text-center text-uppercase text-dark font-weight-bold mb-0">
                            Phantom 3
                            <br />Professional
                          </div>
                          <div className="price text-center">
                            <sup className="text-dark font-weight-normal">from
                              <span className="text-primary font-weight-normal">Rs. </span>
                            </sup>
                            <span className="text-primary font-weight-bold">
                              599.
                            </span>
                            <sup className="text-primary font-weight-bold" style={{fontSize: '55%', top: '-.6em'}}>99</sup>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>




             






              <div className="container deal-section">
                <h3 className="title text-center font-weight-bold">Today's Best Deal</h3>
                <div>
             



<Carousel1/>

                       
                </div>  
              </div>














              <div className="container icon-boxes-section">
                <div className="icon-boxes-container py-4 bg-lighter">

                  <div>
                 
                 < Ico/>
                

                  </div>

                </div>
                <hr className="mt-2 mb-0" />
              </div>




















































             

              <div className="container banner-group-1">
                <div className="categories mb-3">
                  <h3 className="title text-center font-weight-bold mt-4">Explore Popular Categories</h3>
                  <div 
                
                              >
     
                    <Cate />
                  
                    
                  </div>
                </div>


                <div className="row">
                <div className="col-md-4 mb-md-0 mb-2">
                    <div className="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style={{backgroundImage: 'url('+ch5+')'}}>
                      <div className="banner-content position-relative d-flex flex-column justify-content-center">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal d-none d-sm-block font-weight-light">
                          <Link to="/product/new-arrival">Weekend Sale</Link>
                        </h4>
                        {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                        <h3 className="banner-title mb-0 font-weight-bold">
                          <Link to="/product/new-arrival">Home Furnishings
                            <br />Outdoor &amp; Office</Link>
                        </h3>
                        {/* End .banner-title */}
                        <Link to="/product/new-arrival" className="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center d-inline-block">
                          <span>Shop Now </span>
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                      {/* End .banner-content */}
                    </div>
                  </div>
                  
                  
                  <div className="col-md-4 mb-md-0 mb-2">
                    <div className="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style={{backgroundImage: 'url('+ch6+')'}}>
                      <div className="banner-content position-relative d-flex flex-column justify-content-center">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal text-white font-weight-light d-none d-sm-block">
                          <Link to="/product/recommended-product">Amazing Value</Link>
                        </h4>
                        {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                        <h3 className="banner-title text-white mb-0 font-weight-bold">
                          <Link to="/product/recommended-product">Clothes Trending
                            <br />Spring / Summer </Link>
                        </h3>
                        {/* End .banner-title */}
                        <h4 className="banner-text font-weight-normal text-secondary mb-0 font-weight-light">from Rs. 100</h4>
                        <Link to="/product/recommended-product" className="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center">
                          <span>Discover Now </span>
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                      {/* End .banner-content */}
                    </div>
                  </div>
                  <div className="col-md-4 mb-md-0 mb-2">
                    <div className="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style={{backgroundImage: 'url('+ch4+')'}}>
                      <div className="banner-content position-relative d-flex flex-column justify-content-center">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal d-none d-sm-block font-weight-light">
                          <Link to="/product/expert-pick">Headphone Savings</Link>
                        </h4>
                        {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                        <h3 className="banner-title mb-0 font-weight-bold">
                          <Link to="/product/expert-pick">Headphone
                            <br />Trending
                            <br />JBL Harman</Link>
                        </h3>
                        {/* End .banner-title */}
                        <Link to="/product/expert-pick" className="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center">
                          <span>Shop Now </span>
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                      {/* End .banner-content */}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="container deal-section">
                <h3 className="title text-center font-weight-bold">Recommended Products
</h3>
                <div
              
                      >
                      


                    <Carousel2 />



                </div>  
              </div>
              {/* End .container-fluid */}
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="banner banner-rad mt-5">
                      <div className="bg-image d-flex justify-content-center pt-4 pb-4 mb-4" style={{backgroundImage: 'url('+ch3+')'}}>
                        <div className="banner-content position-relative pt-0">
                          <h4 className="banner-subtitle letter-spacing-normal font-size-normal text-white text-center pt-0 mb-1">
                            <a href="#">Discount</a>
                          </h4>
                          {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                          <h3 className="banner-title text-white text-center font-weight-bold mb-0">
                            <a href="#">New Lower Prices On Hundreds
                              <br /> Of Home Appliances</a>
                          </h3>
                          {/* End .banner-title */}
                          <h4 className="banner-text text-secondary text-center font-weight-light text-uppercase">Sale Up 35% Off</h4>
                        </div>
                        {/* End .banner-content */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="container electronics fashion mb-5">
                <div className="bg-lighter trending-products">
                  <div className="heading heading-flex">
                    <div className="heading-left">
                      <h2 className="title font-weight-bold mb-1">Fashion &amp; Clothing</h2>
                      {/* End .title */}
                    </div>
                    {/* End .heading-left */}
                    <div className="heading-right">
                      <ul className="nav nav-pills justify-content-center mr-n3" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase active" id="fashion-best-link" data-toggle="tab" href="#fashion-best-tab" role="tab" aria-controls="fashion-best-tab" aria-selected="true">Best Sellers</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="fashion-trending-link" data-toggle="tab" href="#fashion-trending-tab" role="tab" aria-controls="fashion-trending-tab" aria-selected="false">Trending</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="fashion-woman-link" data-toggle="tab" href="#fashion-woman-tab" role="tab" aria-controls="fashion-woman-tab" aria-selected="false">Woman</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="fashion-man-link" data-toggle="tab" href="#fashion-man-tab" role="tab" aria-controls="fashion-man-tab" aria-selected="false">Man</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="fashion-shoes-link" data-toggle="tab" href="#fashion-shoes-tab" role="tab" aria-controls="fashion-shoes-tab" aria-selected="false">Shoes</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="fashion-accessories-link" data-toggle="tab" href="#fashion-accessories-tab" role="tab" aria-controls="fashion-accessories-tab" aria-selected="false">Accessories</a>
                        </li>
                      </ul>
                    </div>
                    {/* End .heading-right */}
                  </div>
                  {/* End .heading */}
                  <div className="tab-content tab-content-carousel position-relative">
                    <div className="tab-pane p-0 fade show active" id="fashion-best-tab" role="tabpanel" aria-labelledby="fashion-best-link">
                      <div
                    
                                  >
                               <Carousel3/>
                        {/* End .product */}
                      </div>
                      {/* End .owl-carousel*/}
                    </div>
                    {/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="fashion-trending-tab" role="tabpanel" aria-labelledby="fashion-trending-link">
                      <div>
                      <Carousel4/>

                        {/* End .product */}
                      </div>
                      {/* End .owl-carousel */}
                    </div>
                    {/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="fashion-woman-tab" role="tabpanel" aria-labelledby="fashion-woman-link">
                      <div>
                      <Carousel5/>

                        {/* End .product */}
                      </div>
                      {/* End .owl-carousel */}
                    </div>
                    {/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="fashion-man-tab" role="tabpanel" aria-labelledby="fashion-man-link">
                      <div>
                      <Carousel6/>

                        {/* End .product */}
                      </div>
                      {/* End .owl-carousel */}
                    </div>
                    {/* .End .tab-pane */}
                    <div className="tab-pane p-0 fade" id="fashion-accessories-tab" role="tabpanel" aria-labelledby="fashion-accessories-link">
                      <div>
                      <Carousel7/>

                        {/* End .product */}
                      </div>
                      {/* End .owl-carousel */}
                    </div>
                    {/* .End .tab-pane */}
                    </div>
                    
                  {/* End .tab-content */}
                </div>
              </div>
              <div className="container banner-group-2">
                <div className="row row-sm">
                  <div className="col-md-6">
                    <div className="banner banner-overlay bg-image" style={{backgroundImage: 'url('+ch1+')'}}>
                      <div className="banner-content banner-content-left position-relative">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal font-weight-light mb-1">
                          <Link to="categories/Home%20Appliances/all-products">Spring Sale is Coming</Link>
                        </h4>
                        {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                        <h3 className="banner-title font-weight-bold mb-0">
                          <Link to="categories/Home%20Appliances/all-products">All Smartwatches
                            <br />&amp; Accessories Discount</Link>
                        </h3>
                        {/* End .banner-title */}
                        <h4 className="banner-text text-secondary font-weight-normal">15% off</h4>
                        <Link to="categories/Home%20Appliances/all-products" className="btn-product font-size-normal text-uppercase text-dark text-decoration-none btn-rounded">Shop Now&nbsp;&nbsp;
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                      {/* End .banner-content */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="banner banner-overlay bg-image" style={{backgroundImage: 'url('+ch2+')'}}>
                      <div className="banner-content banner-content-left position-relative">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal text-white font-weight-light mb-1">
                          <Link to="/categories/Kitchen%20&%20Dining/all-products">Amazing Value</Link>
                        </h4>
                        {/* End .banner-subtitle letter-spacing-normal font-size-normal */}
                        <h3 className="banner-title text-white font-weight-bold mb-0">
                          <Link to="/categories/Kitchen%20&%20Dining/all-products">Headphones Trending
                            <br />Audio-Technica Professional</Link>
                        </h3>
                        {/* End .banner-title */}
                        <h4 className="banner-text text-secondary font-weight-normal">from $129,99</h4>
                        <Link to="/categories/Kitchen%20&%20Dining/all-products" className="btn-product font-size-normal text-uppercase text-dark text-decoration-none btn-rounded">Shop Now&nbsp;&nbsp;
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                      {/* End .banner-content */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">
                  <div className="arrivals col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">New Arrivals</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/new-arrival" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product10.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }



                      {/* End .product */}
                    </div>
                  </div>
                  <div className="recommend col-lg-4 mb-2 mb-xl-0 order-lg-0 order-md-first">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">Recommended Products</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                      <Link to="/product/recommended-product" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product9.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                  <div className="expert col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">Expert Picks</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/expert-pick" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product11.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">Rs. {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">Rs. {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       Rs. {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bg-white brand-section pt-5 pb-4">
                <div className="container">
                  <div className="owl-carousel owl-simple brands-carousel row cols-2 cols-xs-3 cols-sm-4 cols-lg-5 cols-xxl-6" data-toggle="owl" data-owl-options="{
                              &quot;nav&quot;: false, 
                              &quot;dots&quot;: false,
                              &quot;margin&quot;:  0,
                              &quot;loop&quot;: false,
                              &quot;responsive&quot;: {
                                  &quot;0&quot;: {
                                      &quot;items&quot;:2
                                  },
                                  &quot;480&quot;: {
                                      &quot;items&quot;:3
                                  },
                                  &quot;576&quot;: {
                                      &quot;items&quot;:4
                                  },
                                  &quot;992&quot;: {
                                      &quot;items&quot;:5
                                  },
                                  &quot;1600&quot;: {
                                      &quot;items&quot;:6
                                  }
                              }
                          }">
                    <a href="#" className="brand">
                      <img src="assets/images/brands/1.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/2.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/3.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/4.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/5.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/6.png" alt="Brand Name" width={85} height={35} />
                    </a>
                    <a href="#" className="brand">
                      <img src="assets/images/brands/7.png" alt="Brand Name" width={85} height={35} />
                    </a>
                  </div>
                  <hr className="mt-5 mb-0" />
                </div>
              </div> */}




<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
                 <h4>KartBudget: Affordable Online Shopping Store</h4>

<p>Shopping online with KartBudget comes with many perks. Take your time to explore a wide variety of options at your convenience. Compare prices effortlessly and find exactly what you're searching for, all at the most competitive rates. Whether you're shopping for yourself or seeking <b>cool gifts</b> for your loved ones, KartBudget's got you covered.</p>
<br />
<h4 style={{marginBottom : "1px"}}>An Extensive Product Range</h4>
<p>We offer an extensive inventory spanning from clothing, cosmetics, and home essentials to kitchen products and more. With over 50 lakh products and 650+ categories, KartBudget is your one-stop shop. Our latest collections feature popular items at budget-friendly prices, ensuring you get the best deals on new attire, accessories, and everyday home items.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Fashion for Women</h4>
<p>Discover our Women's Ethnic Wear collection, where you'll find everything from traditional sarees to modern suits and Kurtis. Accessorize with our stunning jewelry, footwear, and accessories for a complete head-turning look. KartBudget is also your go-to destination for affordable Western wear, offering the latest fashion trends along with funky jewelry and accessories.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Fashion for Men</h4>
<p>Explore our Men's Ethnic Wear collection for budget-friendly Sherwanis, Kurta sets, and more. In our extensive range of Men's Western Wear, you'll find t-shirts, jeans, polos, coats, and shirts to suit your style. We have something for everyone, available in various sizes and at affordable prices. Don't forget to check out our accessories and footwear selection.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Top-Quality Beauty Products</h4>
<p>KartBudget prioritizes quality in cosmetics and skincare. Find the <b>best makeup and skincare</b> products, from foundations to lipsticks, that won't harm your skin. Our range includes makeup removers, concealers, primer, mascara, and eye shadow, ensuring you look your best.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Trendy Kids' Collection</h4>
<p>Kids grow quickly, and our kidswear collection is here to keep them stylish without breaking the bank. Shop for girls' rompers and dresses or boys' sets and jeans. We also offer ethnic wear and accessories for kids of all ages, plus a baby care collection with essential products.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Home & Kitchen, Electronics, and More</h4>
<p>For your kitchen, home, and electronic needs, KartBudget provides a wide array of products. From cooking utensils to serveware and kitchen appliances, we offer quality items at reasonable prices. Refresh your home's style with our home furnishing items, including bedsheets, curtains, cushions, and trendy decor items. You can also find electronics and accessories, including chargers, headphones, data cables, and more, with regular sales and discounts.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Health & Fitness</h4>
<p>We understand the importance of health and fitness. KartBudget offers an affordable range of fitness equipment, including dumbbell sets, exercise bands, mats, skipping ropes, and sports kits, to kickstart your fitness journey.</p>
<br />
<h4 style={{marginBottom : "1px"}}>Office Supplies and Stationery</h4>
<p>Stay organized with our range of stationery items. Whether you need supplies for school, college, or the office, we have you covered with notebooks, diaries, notepads, pens, pencils, and more.</p>

<p>Enjoy a simplified shopping experience with KartBudget - your affordable online shopping destination.</p>

            </div>
            </div>
            </div>





              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
          {/* Mobile Menu */}
          
          {/* <div className="container newsletter-popup-container mfp-hide" id="newsletter-popup-form">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row no-gutters bg-white newsletter-popup-content">
                  <div className="col-xl-3-5col col-lg-7 banner-content-wrap">
                    <div className="banner-content text-center">
                      <img src="assets/images/popup/newsletter/logo.png" className="logo" alt="logo" width={60} height={15} />
                      <h2 className="banner-title">get
                        <span>25</span>
                        <mark>%</mark> off</h2>
                      <p>Subscribe to the Molla eCommerce newsletter to receive timely updates from your favorite products.</p>
                      <form action="#">
                        <div className="input-group input-group-round">
                          <input type="email" className="form-control form-control-white" placeholder="Your Email Address" aria-label="Email Adress" required />
                          <div className="input-group-append">
                            <button className="btn" type="submit">
                              <span>go</span>
                            </button>
                          </div>
                        </div>
                      </form>
                        <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="register-policy-2" required />
                        <label className="custom-control-label" htmlFor="register-policy-2">Do not show this popup again</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2-5col col-lg-5 ">
                    <img src="assets/images/popup/newsletter/img-1.jpg" className="newsletter-img" alt="newsletter" width={700} height={400} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        )
    
}

export default Home;