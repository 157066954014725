import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/logo-kart1.png';
import payment from '../asset1/images/payments.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
  const Footer =(props)=> {
    const [MainCatogories , setMainCategories] = useState([])
    useEffect(() =>{

    fetch("https://kartbudget-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
              if(c < 10){
                dat.push(res55)
                dat1.push(0)

              }
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)

        })
      },[])

  //  history = useHistory();
  
        return (
            <>
          <footer className="footer footer-2 font-weight-normal second-primary-color" style={{backgroundColor: '#222'}}>
              
              {/* End .cta */}
              <div className="container">
                <hr className="mt-0 mb-0" style={{borderColor: '#444'}} />
              </div>
              <div className="footer-middle border-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-2-5cols">
                      <div className="widget widget-about mb-4">
                        <img src={logo1} className="footer-logo" alt="Footer Logo" width={105} height={25} />
                        <p className="font-weight-normal second-primary-color">Welcome to Kart Budget - Affordable Online Shopping Store for Quality of Products across Pakistan. Discover a budget-friendly items, from fashion to electronics, Home Appliances, Toy & Games, Health & Beauty, Tools & Gadgets, and enjoy great savings with a heavy discount.
                         </p>
                        <div className="widget-about-info">
                          <div className="row">
                            <div className="col-sm-6 col-md-6">
                              <span className="widget-about-title text-white">Got Question? Call us 24/7</span>
                              <a href="tel:+923306007333" className="text-primary">+92 330 6007333</a>
                            </div>
                            {/* End .col-sm-6 */}
                            <div className="col-sm-6 col-md-6">
                              <span className="pl-3 widget-about-title text-white">Payment Method</span>
                              <figure className="pl-3 mb-0 footer-payments">
                                <img src={payment} alt="Payment" width={272} height={20} />
                              </figure>
                              {/* End .footer-payments */}
                            </div>
                            {/* End .col-sm-6 */}
                          </div>
                          {/* End .row */}
                        </div>
                        {/* End .widget-about-info */}
                      </div>
                      {/* End .widget about-widget */}
                    </div>
                    {/* End .col-sm-12 col-lg-3 */}
                    <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title text-white">Information</h4>
                        {/* End .widget-title */}
                        <ul className="widget-list">
                          {/* <li>
                            <Link to="/about">About Kart Budget</Link>
                          </li> */}
                           {/* <li>
                            <Link to="/">Returns</Link>
                          </li> */}
                          <li>
                            <Link to="/Shipping">Shipping</Link>
                          </li>
                          <li>
                            <Link to="/term-policy">Terms and conditions</Link>
                          </li>
                          <li>
                            <Link to="/privacy">Privacy Policy</Link>
                          </li>
                          {/* <li>
                            <Link to="/">How to shop on Kart Budget</Link>
                          </li> */}
                          {/* <li>
                            <Link to="/help">FAQ</Link>
                          </li> */}
                          <li>
                            <Link to="/contact">Contact us</Link>
                          </li>
                          <li>
                            <Link to="/login">Log in</Link>
                          </li>
                        </ul>
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-4 col-lg-3 */}
                  
                    {/* End .col-sm-4 col-lg-3 */}
                    <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title text-white">Customer Service</h4>
                        {/* <h4 className="widget-title text-white">My Account</h4> */}
                        {/* End .widget-title */}
                        <ul className="widget-list">
                          <li>
                            <Link to="/login">Sign In</Link>
                          </li>
                          <li>
                            <Link to="/cart">View Cart</Link>
                          </li>
                          <li>
                            <Link to="/whislist">My Wishlist</Link>
                          </li>
                          <li>
                            <Link to="/track">Track My Order</Link>
                          </li>
                          <li>
                            <Link to="/FAQ">Help</Link>
                          </li>
                        </ul>
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-64 col-lg-3 */}

  <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title text-white">Category</h4>
                        <ul className="widget-list">
                        {MainCatogories.map((res,i)=>{
                    return (
                      <>  
                       <li>
                            <a href={"/categories/"+res.MainCategories +"/all-products"}>{res.MainCategories}</a>
                          </li>
                           </>
                    
                    )
                  
                  })
                

                              }
                        </ul>
                      </div>
                    </div>


                  </div>
                  {/* End .row */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-middle */}
              <div className="footer-bottom font-weight-normal">
                <div className="container">
                  <p className="font-weight-normal ml-lg-2 second-primary-color" style={{fontSize : "13px", fontWeight :"500"}}>Copyright © 2023 Kart Budget. All Rights Reserved. </p>
                  {/* End .footer-copyright */}
                  {/* <ul className="footer-menu justify-content-center">
                    <li>
                      <a href="#">Terms Of Use</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul> */}
                  {/* End .footer-menu */}
                  <div className="social-icons social-icons-color justify-content-center">
                    <span className="social-label">Social Media</span>
                    <a href="https://www.facebook.com/kartbudget50" className="social-icon social-facebook" title="Facebook" target="_blank">
                      <i className="icon-facebook-f" />
                    </a>
                    {/* <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank">
                      <i className="icon-twitter" />
                    </a> */}
                    <a href="https://www.facebook.com/kartbudget50" className="social-icon social-instagram" title="Instagram" target="_blank">
                      <i className="icon-instagram" />
                    </a>
                    <a href="#" className="social-icon social-youtube" title="Youtube" target="_blank">
                      <i className="icon-youtube" />
                    </a>
                  </div>
                  {/* End .soial-icons */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-bottom */}
            </footer>
            
         
            </>
        )
    
}

export default Footer;