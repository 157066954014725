import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Term & Conditions</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h2>Standard Shipping</h2>

<p><strong>Delivery Timeframe:</strong><br/>
Our standard shipping strategy is to deliver items within Pakistan within 1-5 days after we receive your order. The exact shipping duration depends on your location within Pakistan. We use reputable courier services to ship your purchased products promptly. We prioritize quick shipment and collaborate with well-known courier companies to ensure a smooth delivery process. Typically, packages are delivered within 2-3 business days after we dispatch them.</p>

<p><strong>Signature Requirement:</strong><br/>
Unless specified by the carrier, we do not require a signature for delivery. If you have any questions about the speed of delivery, please feel free to email us at customerservice@kartbudget.com.</p>

<p><strong>Nonrefundable Shipping Costs:</strong><br/>
Please be aware that if you need to return items for a refund, the shipping costs are non-refundable. Additionally, if you return an item that originally qualified for a "free shipping" offer, the cost of shipping that item by PX may be deducted from the refunded amount to cover the expense of the initial shipment.</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;